import './App.css';
import {useState, useEffect} from "react";
import './Pixels.js';
import Pixels from './Pixels.js';
import Colors from './Colors.js';

function App(props) {
   //const [row, setRow]=useState(Pixels(16*16));
   const [plateSize, setPlateSize]=useState(() => JSON.parse(localStorage.getItem("plateSize")) ?? 16*16);
   const [row, setRow]=useState(() => JSON.parse(localStorage.getItem("row")) ?? Pixels(plateSize));
   const [color, setColor]=useState(() => {
       if (JSON.parse(localStorage.getItem("color") === null)) {return Colors("small");}
       else return JSON.parse(localStorage.getItem("color"));
   });
   const [selectedColor, setSelectedColor]=useState("black");
   const pixels = row.length;
   const numberofRows= Math.sqrt(pixels);
   const basePlate=splitRow(row); 
   const [checked16,setChecked16]=useState(() => { 
       if (localStorage.getItem("checked16")===null) {return true;}
        else return localStorage.getItem("checked16")==="true";
       });
       
   const [checked48,setChecked48]=useState(() => localStorage.getItem("checked48")==="true" ?? false);
   console.log(localStorage.getItem("checked16"));
   console.log(localStorage.getItem("checked48"));

   useEffect (() => {
    localStorage.setItem("plateSize",JSON.stringify(plateSize));
},[plateSize]);
   
   useEffect (() => {
       localStorage.setItem("row",JSON.stringify(row));
   },[row]);

   useEffect (() => {
       localStorage.setItem("checked16",checked16);
   },[checked16]);

   useEffect (() => {
    localStorage.setItem("checked48",checked48);
},[checked48]);

    useEffect (() => {
    localStorage.setItem("color",JSON.stringify(color));
},[color]);

// Update colors in the baseplate when clicked on a pixel.
   function handleClickPixel(e) {
       const oldColor=row[e.target.id].color;
       const pixelSize= plateSize===48*48? " pxbutton48" : " pxbutton16";   
        const tempRow = row.map(px => {
         if (!px.selected &&px.id===parseInt(e.target.id)) return ({
                id: px.id,
                color: selectedColor,
                selected: px.selected,
                class: selectedColor +pixelSize
            })
            return px;
        });
        console.log(oldColor);
        setRow(tempRow);

        const tempColor = color.map(color => {
            if (color.color === selectedColor && selectedColor!==oldColor) return ({
                   id: color.id,
                   color: color.color,
                   selected: color.selected,
                   class: color.class,
                   count: color.count+1
               })            
           if (color.color === oldColor && selectedColor!==oldColor) return ({
                id: color.id,
                color: color.color,
                selected: color.selected,
                class: color.class,
                count: color.count-1
            })
            console.log(color);
            return color;
    });
    setColor(tempColor);           
    return;
   }

// Split the pixel matrix in an array of rows.
   function splitRow(){
      const tempArray=[];  
      for (let i=0;i<numberofRows;i++){
        const tempRow = row.slice(numberofRows*i,numberofRows*(i+1));
        tempArray.push(tempRow);
        }
        return tempArray;
   }

   function createRow (rowx,index) { 
    if(plateSize===48*48){
        return(
            <div key={index} className="container">
                <div className="wrapper48">
                {rowx.map((px) => <button onClick={(e) => handleClickPixel(e)} id={px.id} 
                key={px.id} value={px.color} selected={px.selected} className={px.class}></button>)}
                </div>
            </div>
       );
    }
    if(plateSize===16*16){
        return(
            <div key={index}>
                {rowx.map((px) => <button onClick={(e) => handleClickPixel(e)} id={px.id} 
                key={px.id} value={px.color} selected={px.selected} className={px.class}></button>)}
            </div>
       );
    }
   }

   //Set selected color 
    function handleClickColor(e){
       const selection = " selected";
       console.log(e.target.id);
       const tempColor= color.map(color => {
        if (!color.selected &&color.id===parseInt(e.target.id)) return ({
               id: color.id,
               color: color.color,
               selected: !color.selected,
               class: selection,
               count: color.count
           })
        if (color.selected &&!(color.id===parseInt(e.target.id))) return ({
            id: color.id,
            color: color.color,
            selected: !color.selected,
            class: "",
            count: color.count
        })
        return color;
       });
       setColor(tempColor);
       setSelectedColor(e.target.value);
       console.log(selectedColor);
   }

   function handleChange48(e){
       console.log("radio 48 clicked");
       setRow(Pixels(48*48));
       setPlateSize(48*48);
       setColor(Colors("large"));
       setChecked16(false);
       setChecked48(true); 
       setSelectedColor("black");
   }

   function handleChange16(e){
    console.log("radio 16 clicked");
    setRow(Pixels(16*16));
    setPlateSize(16*16);
    setColor(Colors("small"));
    setChecked16(true);
    setChecked48(false); 
    setSelectedColor("black");
}
    //Reset the entire board
    function handleClickReset(e){
        if (checked16 === true) {
            handleChange16();
        }
        if (checked48 === true) {
            handleChange48();
        }
    }
   
  return (
    <div className="App">
        <h1>Your brick mosaic</h1>
        <button onClick={(e)=> handleClickReset(e)}>Reset</button>
        <p></p>
        {basePlate.map((row,index) => createRow(row,index))}
        <h2>Pick a color</h2>
        <div>
            {color.map((color) => <button onClick={(e) => handleClickColor(e)} id={color.id}
            key={color.id} value={color.color} className={color.color + color.class}>x</button>)}
        </div>
        <div>
            <label><input type="radio" value="16" checked={checked16} onChange={(e)=>handleChange16(e)} />16*16</label>
            <label><input type="radio" value="48" checked={checked48} onChange={(e)=>handleChange48(e)} />48*48</label>
        </div>
        <h2>{pixels} bricks used</h2>
        <ul>
            {color.map((color)=> <li key={color.id}>{color.color} {color.count}</li>)}
        </ul>
    </div>
  );
}

export default App;
