export default function Pixels(number){

    const pixelArray=[];
    if (number ===16*16){
        for(let i=0;i<number;i++) {
            const pixel ={
                id: i,
                color: "lgrey",
                selected: false,
                class: "lgrey pxbutton16"
             }; 
            pixelArray.push(pixel);
        }
        return pixelArray;
    }
    if (number ===48*48){
        for(let i=0;i<number;i++) {
            const pixel ={
                id: i,
                color: "lgrey",
                selected: false,
                class: "lgrey pxbutton48"
             }; 
            pixelArray.push(pixel);
        }
        return pixelArray;
    }

}
