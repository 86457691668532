export default function Colors(size){
 
    const colorsChoice = ["lgrey","dgrey","black","red","white","blue","green","yellow"];
    const tempArray=[];
    for(let i=0;i<colorsChoice.length;i++) {
        if(colorsChoice[i]==="black"){
        const color ={
            id: i,
            color: colorsChoice[i],
            selected: true,
            class: " selected",
            count: 0
         }; 
        tempArray.push(color);
        }if(colorsChoice[i]==="lgrey" && size==="small"){
            const color ={
                id: i,
                color: colorsChoice[i],
                selected: false,
                class: "",
                count: 256
             }; 
            tempArray.push(color);
        } if(colorsChoice[i]==="lgrey" && size==="large"){
            const color ={
                id: i,
                color: colorsChoice[i],
                selected: false,
                class: "",
                count: 2304
             }; 
            tempArray.push(color);
        } if(colorsChoice[i]!=="black" && colorsChoice[i]!=="lgrey"){
            const color ={
                id: i,
                color: colorsChoice[i],
                selected: false,
                class: "",
                count:0
             }; 
             tempArray.push(color);
            }
    }
    console.log(tempArray);
    return tempArray;
}
